import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import marketing from '../../../services/marketing';
import PixelsService from '../../../services/pixels';
import AudioEyeSupport from '../../../services/audioEyeSupport';
function DeclinedTemporal() {    

    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const setting = useStoreState(state => state.siteSettingsModel);
    const productSettings = useStoreState(state => state.siteSettingsModel.productSettings);
    const showOnDeclined = useStoreState(state => state.siteSettingsModel.showOnDeclined);
    const webAssetsBaseUrlSettings = useStoreState(state => state.siteSettingsModel.webAssetsBaseUrlSettings);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const alResult = useStoreState(state => state.alResult);
    const setAlResult = useStoreActions(actions => actions.setAlResult);
    const utms = new marketing()
    const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
    const declineTemporalMessage = "Unfortunately, we are not able to complete the processing of your application right now. Please check your email for important information from us regarding additional information we need.";
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
    const PixelService = new PixelsService();
    const ada = new AudioEyeSupport();
    const WaterfallEvents = { "DM" : 9, "PREQUAL" : 4, "CFC" : 16, "STANDALONE" : 22, "CS" : 28};

    useEffect(() => {
        const disableBackButton = (event) => {
          event.preventDefault();
          window.history.forward();
        };
    
        window.addEventListener('popstate', disableBackButton);
    
        return () => {
            //window.removeEventListener('popstate', disableBackButton);
        };
      }, []);

    useEffect(() => {
        
        if(alResult === false){
            PixelService.pageView("/pendinfo").event("google", Flow, "pendinfo");
            ada.setDYContext( { type: "OTHER" , data:["Declined", applicationResult.ref_number ]});
            ada.sendDYEvent({name : 'Declined'})
            utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, applicationResult.ref_number, productSettings.ProductId, "Flow");
            if(typeof window.nid === 'function') {
                window.nid('closeSession')
            }
            getRoutingSettings({ Url: setting.domain, Route: "/declined"});
            setAlResult(true)
        }
       // eslint-disable-next-line  react-hooks/exhaustive-deps
    },[])

    function getNoReplyEmail(){
        if(productSettings.NoReplyEmail){
            return productSettings.NoReplyEmail
        }
        if(brandSettings.ProductName === "Revel"){
            return "noreply@revelcard.com";
        }else{
            return "noreply@your"+brandSettings.ProductName.toLowerCase()+"card.com";
        }
    }

    return (
        <div className="resultpage">
            <div className="ribbon" style={{ backgroundColor: brandSettings.Primary }}>
                {Flow === "PREQUAL" ? <h1>Your Pre-qualification Results Are...</h1> : <h1>Thank you for applying...</h1>}
            </div>
            <div className="container">
                {
                    // eslint-disable-next-line  no-whitespace-before-property
                    applicationResult.ref_number !== "" && applicationResult.ref_number !== null ?
                    <div className="row" >
                        <div className="col-lg-12 text-center">
                            <h4 style={{"color" : "#f56928"}}>Reference #: {applicationResult.ref_number}</h4>
                        </div>
                    </div>
                    : 
                    <></>
                }         
            
                <div className='row'>
                    <div className='card gbCard'>
                        <div className='card-body no-bottom-margin  row' style={{"marginBottom" : "0 !important"}}>
                            <div className={brandSettings.ProductName === "Revel" ? "col-lg-4 text-center" : "col-lg-4"}><img style={{width :(brandSettings.ProductName === "Revel" ? "80%" : "")}} className="col-lg-12" src={(brandSettings.ProductName === "Surge") ? ((Flow === "DM") ? webAssetsBaseUrlSettings.CdnImage+"Surge/all-colors.png" : webAssetsBaseUrlSettings.CdnImage+"Surge/card-silver.png") : brandSettings.CardImage} alt={brandSettings.ProductName} /></div>
                            <div className="col-lg-8 pt-0 result-message declined">
                                <p className='text-justify'>
                                <strong>Thank you for your interest in {brandSettings.ProductName.toLowerCase() === 'fit' ? `${brandSettings.ProductName.toUpperCase()}` : `${brandSettings.ProductName}`} Mastercard. At this time, a credit offer is not available for the following reason(s):</strong>
                                </p>
                                <p className="decline-reason gbresponse">
                                    {declineTemporalMessage}
                                </p>
                                {
                                    !showOnDeclined ?
                                    <p className='text-justify mb-0'>
                                        <strong>
                                                Please check your email for a message from <span style={{ color: "#008cba" }}><u>{ getNoReplyEmail() }</u></span>. You
                                                may need to review your junk email for this message.
                                        </strong>
                                    </p>
                                    :
                                    <></>
                                }
                                
                            </div>
                        </div>
                    </div>                  
                </div>
            </div>
        </div>
    );

}

export default DeclinedTemporal;